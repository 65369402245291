<template>
  <div class="futures">
    <el-row type="flex" justify="center" class="row-bg2">
      <el-col :span="22">
        <el-container>
          <el-header>
            <h2> 交易记录 </h2>
          </el-header>
          <el-container>
            <el-main>
              <el-button @click="clearFilter">清除所有过滤器</el-button>
              <el-table :row-class-name="tableRowClassName" ref="filterTable" @filter-change="httpGetFuturesTraders"
                        :data="tableData" style="width: 100%">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <el-form-item label="symbol"><span>{{ props.row.symbol }}</span></el-form-item>
                      <el-form-item label="strategy"><span>{{ props.row.strategy }}</span></el-form-item>
                      <el-form-item label="side"><span>{{ props.row.side }}</span></el-form-item>
                      <el-form-item label="status"><span>{{ props.row.status }}</span></el-form-item>
                      <el-form-item label="open_qty"><span>{{ props.row.open_qty }}</span></el-form-item>
                      <el-form-item label="open_price"><span>{{ props.row.open_price }}</span></el-form-item>
                      <el-form-item label="opened_qty"><span>{{ props.row.opened_qty }}</span></el-form-item>
                      <el-form-item label="opened_price"><span>{{ props.row.opened_price }}</span></el-form-item>
                      <el-form-item label="opened_values"><span>{{ props.row.opened_values }}</span></el-form-item>
                      <el-form-item label="close_price"><span>{{ props.row.close_price }}</span></el-form-item>
											<el-form-item label="closed_values"><span>{{ props.row.closed_values }}</span></el-form-item>
                      <el-form-item label="closed_price"><span>{{ props.row.closed_price }}</span></el-form-item>
											<el-form-item label="closed_qty"><span>{{ props.row.closed_qty }}</span></el-form-item>
                      <el-form-item label="u_profit ($)"><span>{{ props.row.u_profit | numFilter }}</span></el-form-item>
                      <el-form-item label="u_roe (%)"><span>{{ props.row.u_roe | numFilter }}</span></el-form-item>
                      <el-form-item label="profit ($)"><span>{{ props.row.profit | numFilter }}</span></el-form-item>
                      <el-form-item label="roe (%)"><span>{{ props.row.roe | numFilter }}</span></el-form-item>
                      <el-form-item label="opened_time"><span>{{ props.row.opened_ts | dateFormat }}</span></el-form-item>
                      <el-form-item label="closed_time"><span>{{ props.row.closed_ts | dateFormat }}</span></el-form-item>
                    </el-form>
                  </template>
                </el-table-column>

                <el-table-column :filters="symbolData" :filter-method="filterSymbol" prop="symbol"
                                 label="symbol"></el-table-column>
                <el-table-column prop="strategy" label="strategy"></el-table-column>
                <el-table-column :filters="side_filters" :filter-method="filterSide" prop="side"
                                 label="side"></el-table-column>
                <el-table-column :filters="roe_filters" :filter-method="filterRoe" label="roe (%)">
                  <template
                      slot-scope="item">{{ item.row.roe | numFilter }}
                  </template>
                </el-table-column>
                <el-table-column label="profit ($)">
                  <template
                      slot-scope="item">{{ item.row.profit | numFilter }}
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="status"></el-table-column>
                <el-table-column label="opened_time">
                  <template
                      slot-scope="item">{{ item.row.opened_ts | dateFormat }}
                  </template>
                </el-table-column>
                <el-table-column label="closed_time">
                  <template
                      slot-scope="item">{{ item.row.closed_ts | dateFormat }}
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @size-change="handleSizeChange" @current-change="pageChange" :page-sizes="[5, 10, 20, 50]"
                             :page-size="size" :current-page="page" background layout="sizes, prev, pager, next, total"
                             :total="tableCount">
              </el-pagination>
            </el-main>
          </el-container>
        </el-container>
      </el-col>
    </el-row>
  </div>
</template>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-pagination {
  margin-top: 30px;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 110px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>

<script>
export default {
  methods: {
    // 表格背景
    tableRowClassName({row}) {
      // console.log(row, rowIndex)
      if (row.roe > 0) {
        return 'success-row';
      } else {
        return '';
      }
    },

    clearFilter() {
      this.symbol = ""
      this.roe = ""
      this.side = ""
      this.$refs.filterTable.clearFilter();
      this.httpGetFuturesTraders()
    },

    // 请求指标分析数据
    httpGetFuturesTraders() {
      const tmpPage = this.page - 1;
      this.$http
          .get('/futures/traders?' + 'size=' + this.size + '&page=' + tmpPage +
              "&symbol=" + this.symbol + "&side=" + this.side + "&roe=" + this.roe)
          .then(response => {
            this.tableData = response.data.data.list
            this.tableCount = response.data.data.count
            // console.log(response.data.data)
          })
          .catch(function (error) {
            console.log(error)
          });
    },

    // 请求交易币列表
    httpGetSymbolList() {
      this.$http
          .get('/futures/coinlist')
          .then(response => {
            this.symbolData = response.data.data
            // console.log(response.data.data)
          })
          .catch(function (error) {
            console.log(error)
          });
    },

    handleSizeChange(val) {
      this.size = val
      this.httpGetFuturesTraders()
    },

    filterSymbol(value) {
      this.symbol = value
      return true
    },
    filterSide(value) {
      this.side = value
      return true
    },
    filterRoe(value) {
      this.roe = value
      return true
    },

    pageChange(val) {
      this.page = val
      this.httpGetFuturesTraders()
    }
  },

  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
    dateFormat: function (timestamp) {
      if (timestamp && timestamp !== "") {
        var date = new Date(timestamp); //如果date为13位不需要乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return Y + M + D + h + m + s;
      }
      return "";
    },
  },

  data() {
    return {
      tableData: [],
      tableCount: 0,
      symbolData: [],
      idcName: '',
      symbol: '',
      size: 10,
      side: '',
      roe: '',
      page: 1,
      side_filters: [{"text": "SELL", "value": "SELL"}, {"text": "BUY", "value": "BUY"}],
      roe_filters: [
        {"text": "< 0%", "value": "-1"}, {"text": "> 0%", "value": "0"},
        {"text": "> 1%", "value": "1"}, {"text": "> 2%", "value": "2"},
        {"text": "> 3%", "value": "3"}, {"text": "> 4%", "value": "4"},
        {"text": "> 5%", "value": "5"}, {"text": "> 10%", "value": "10"},
      ]
    }
  },

  mounted() {
    this.idcName = this.$route.params.name
    this.httpGetSymbolList()
    this.httpGetFuturesTraders()
  }
}
</script>
